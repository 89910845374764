const defaultOptions = {
  perView: 3,
  breakpoints: {
    992: {
      perView: 2,
    },
  },
  type: "carousel",
};

export const CAROUSEL_CUSTOMERS_ALSO_BOUGHT = {
  element: ".single-product__customers-also-bought .carousel",
  options: defaultOptions,
};

export const CAROUSEL_FEATURED_CATEGORIES = {
  element: ".home-featured-categories .carousel",
  options: defaultOptions,
};

export const CAROUSEL_PRODUCT_MAIN = {
  element: ".single-product__carousel .carousel",
  options: {
    ...defaultOptions,
    breakpoints: {
      768: {
        perView: 1,
      },
    },
  },
};

export const CAROUSELS_FEATURED_PRODUCTS = [
  {
    element: ".featured-products--with-tabs .carousel_0",
    options: defaultOptions,
  },

  {
    element: ".featured-products--with-tabs .carousel_1",
    options: defaultOptions,
  },

  {
    element: ".featured-products--with-tabs .carousel_2",
    options: defaultOptions,
  },
];

export const CAROUSEL_INSTAGRAM_FEED = {
  element: ".home-instagram .carousel",
  options: {
    ...defaultOptions,
    perView: 5,
  },
};

export const CAROUSEL_BLOG_FEATURED_PRODUCTS = {
  element: ".blog__featured-products .carousel",
  options: {
    ...defaultOptions,
  },
};
