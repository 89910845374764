export const successLog = (text) => {
  console.log(`%c ✔️ ${text}`, "color: #27ae60");
};

export const warningLog = (text) => {
  console.log(`%c ⚙️ ${text}`, "color: #f1c40f");
};

export const getDevelopmentMode = () => {
  return "env" in window.WP == true && window.WP.env;
};

export const isDevelopmentMode = () => getDevelopmentMode() === "DEVELOPMENT";

export const runServerAction = (actionName, params) => {
  if (!window.WP) throw new Error("window.WP is undefined");

  const startTime = Date.now();
  const isDevelopment = isDevelopmentMode();
  let body = `action=${actionName}&_ajax_nonce=${window.WP.ajaxNonce}`;

  if (params)
    Object.keys(params).forEach((key) => {
      body += `&${key}=${params[key]}`;
    });

  isDevelopment &&
    warningLog(
      `ACTION "${actionName}" ${
        params.length > 0
          ? `STARTED with params: ${JSON.stringify(params)}`
          : "STARTED without params"
      }`
    );

  const request = fetch(window.WP.ajaxUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    },
    body: body,
  }).then((response) => {
    return response.json();
  });

  isDevelopment &&
    request.then((result) => {
      successLog(
        `ACTION "${actionName}" FINISHED WITH RESULT: ${JSON.stringify(result)}`
      );
      successLog(`ACTION FINISHED IN ${Date.now() - startTime}ms`);
      console.log("\n");
    });

  return request;
};

export const makeBodyScrollable = (scrollable = true) => {
  const html = document.documentElement.classList;
  const className = "hidden-scroll";

  scrollable ? html.add(className) : html.remove(className);
};

export const validateForm = () => {
  const formClass = ".form--to-validate";
  const formRequiredSpecifier = "[data-required], .validate-required input";
  const formRequiredTextAttribute = "data-required-text";
  const formsToValidate = document.querySelectorAll(formClass);

  if (formsToValidate.length < 1) return false;

  // Iterate over all forms to validate
  formsToValidate.forEach((form) => {
    form.addEventListener("submit", (event) => {
      let invalidInputs = 0;

      // Iterate over all required fields
      event.target.querySelectorAll(formRequiredSpecifier).forEach((input) => {
        const inputValue = input.value.toString();
        let inputIsValid = false;
        let regexPattern = false;

        if (input.tagName === "INPUT") {
          switch (input.type) {
            case "email":
              regexPattern = /\S+@\S+\.\S+/;
              break;

            case "phone":
              regexPattern = /^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/;
              break;

            case "checkbox":
              inputIsValid = input.checked;
              break;

            default:
              inputIsValid = inputValue !== "";
          }
        } else if (input.tagName === "TEXTAREA") {
          inputIsValid = inputValue !== "";
        } else if (input.tagName === "SELECT") {
          inputIsValid = input.selected;
        }

        if (!inputIsValid && regexPattern !== false) {
          inputIsValid = regexPattern.test(inputValue);
        }

        // Clear the notification
        const nextElement =
          input.type === "checkbox" ? input : input.nextSibling;
        let hasErrorLabel =
          nextElement && nextElement.classList.contains("error");

        if (inputIsValid && hasErrorLabel && nextElement.tagName === "LABEL") {
          input.parentNode.removeChild(nextElement);
        }

        if (input.type === "checkbox") {
          !inputIsValid
            ? input.classList.add("error")
            : input.classList.remove("error");

          hasErrorLabel = true;
        }

        if (!inputIsValid && !hasErrorLabel) {
          // Show notification
          const label = document.createElement("label");

          label.classList.add("error");
          label.textContent =
            input.getAttribute(formRequiredTextAttribute) ||
            "To pole jest wymagane";

          input.parentNode.insertBefore(label, input.nextSibling);
        }

        if (!inputIsValid) invalidInputs++;
      });

      if (invalidInputs > 0) {
        event.preventDefault();
      }
    });
  });
};

export const blockScroll = () => {
  let scrollbarWidth = (window.innerWidth - document.body.clientWidth) + 'px';

  document.documentElement.style = `overflow: hidden !important; margin-right: ${scrollbarWidth}`;
};

export const unblockScroll = () => {
  document.documentElement.style.removeProperty('overflow');
  document.documentElement.style.removeProperty('margin-right');
};