import Vue from "vue/dist/vue.esm";
import Glide from "@glidejs/glide";
import IMask from "imask";

import VRuntimeTemplate from "v-runtime-template";
import ZoomOnHover from "vue-zoom-on-hover";
import Spinner from "./components/Spinner.vue";
import Icon from "./components/Icon.vue";
import Countdown from "./components/Countdown.vue";

import {
  runServerAction,
  warningLog,
  makeBodyScrollable,
  isDevelopmentMode,
  getDevelopmentMode,
  validateForm,
} from "./helpers/main";
import {
  CAROUSEL_FEATURED_CATEGORIES,
  CAROUSEL_CUSTOMERS_ALSO_BOUGHT,
  CAROUSELS_FEATURED_PRODUCTS,
  CAROUSEL_INSTAGRAM_FEED,
  CAROUSEL_PRODUCT_MAIN,
  CAROUSEL_BLOG_FEATURED_PRODUCTS,
} from "./helpers/carousels";
import { ACTIONS, ASIDES, LOCAL_STORAGE } from "./helpers/dictionary";

const ITEM_ANIMATABLE_CLASS = "running";
const ITEM_VISIBLE_CLASS = "opened";
const MOBILE_BREAKPOINT = 991;

Vue.use(ZoomOnHover);

new Vue({
  el: "#store",
  components: {
    Spinner,
    Icon,
    Countdown,
    VRuntimeTemplate,
  },
  data: {
    carousels: [],
    cart: {
      products: [],
      summary: {
        total: "",
        tax: "",
        coupons: [],
        amountLeftToFreeShipping: 0,
        freeShipping: false,
      },
    },
    checkout: {
      wantInvoice: false,
      wantDifferentShippingAddress: false,
      wantToCreateAnAccount: false,
      shippingMethod: false,
      idsOfInPostShippingMethod: [],
      closestPoints: [],
      inPostChosenPoint: {},
      couponCodes: [],
      details: {
        city: false,
      },
    },
    productArchive: {
      page: 1,
      termSlug: "",
      termType: "",
      filters: [],
      loadedProducts: [],
      isLoading: false,
    },
    cartCoupon: "",
    singleProduct: {
      id: 0,
      parentId: 0,
      photo: "",
      photos: [],
      price: "",
      quantity: "",
      size: "",
      color: "",
      available: false,
      availabilityChecked: false,
      inStock: 0,
      onSale: false,
    },
    order: {
      revenue: 0.0,
      transactionId: 0,
      products: [
        // {
        //  id'
        // 'name'
        // 'price'
        // 'brand'
        // 'quantity'
        // }
      ],
    },
    favouriteProducts: [],
    asideOpened: false,
    submenuOpened: false,
    searchOpened: false,
    filterOpened: false,
    singleProductStickyBarVisible: false,
    scrollWatcherEnabled: true,
    home: {
      featuredGroupIndex: 0,
      instagramPhotos: [],
    },
    notification: {
      content: "",
      type: "info",
    },
    status: {
      isAddToCartButtonBlockedBy: false,
      isDuringRemoveFromCart: false,
      isDuringProductCountIncrease: false,
      isDuringProductCountDecrease: false,
      isAsyncRequestInProgress: false,
    },
    productReview: {
      rating: 5,
    },
  },
  computed: {
    isMobileScreen: () => window.innerWidth <= MOBILE_BREAKPOINT,

    isInPost() {
      return this.checkout.idsOfInPostShippingMethod.includes(
        this.checkout.shippingMethod
      );
    },

    isDpd() {
      return this.checkout.idsOfDpdShippingMethod.includes(
        this.checkout.shippingMethod
      );
    },

    totalProductsCount() {
      let count = 0;

      if (this.cart && typeof this.cart["products"] !== "undefined") {
        this.cart.products.forEach(
          (product) => (count += parseInt(product.quantity))
        );
      }

      return count;
    },

    getInterestsRateText() {
      const variantNumber = this.getRandomInteger(1, 2);

      switch (variantNumber) {
        case 1:
          const numberOfCustomers = this.getRandomInteger(3, 8);

          return `${numberOfCustomers +
            (numberOfCustomers <= 3
              ? " osoby oglądały"
              : " osób oglądało")} dzisiaj ten produkt w ciągu ostatnich 8 godzin`;
        case 2:
          const numberOfProductsBought = this.getRandomInteger(6, 13);

          return `Dzisiaj ten produkt kupiono już ${numberOfProductsBought} razy!`;
      }
    },
  },

  watch: {
    async isInPost(value) {
      if (value === false) return;

      this.checkoutGetInPostPoints();
    },

    checkout: {
      handler(value) {
        {
          if (window.InPostMap)
            window.InPostMap.searchPlace(value.details.city);

          if (value.wantDifferentShippingAddress === true) {
            // this.cartValidatePostCode("[name=shipping_postcode]");
          }
        }
      },
      deep: true,
    },

    home: {
      handler(value) {
        {
          this.homeRefreshFeaturedProductsCarousel(value.featuredGroupIndex);
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (isDevelopmentMode()) {
      warningLog(`App started in ${getDevelopmentMode()} mode`);
    }

    this.initializeState();
    this.homeWatchInstagramSectionVisible();

    validateForm();

    // Add event listeners only on checkout page
    if (
      document.querySelectorAll(".checkout--page").length === 1 &&
      document.querySelectorAll(".page-thank-you").length === 0
    ) {
      this.checkoutRegisterEvents();
      this.facebookTrackCheckout();
      this.googleAnalyticsTrackCheckout();
      this.gazetaPixelTrackPurchaseStartEvent();
    }

    // Add event listeners only on thank you page
    if (document.querySelectorAll(".page-thank-you").length === 1) {
      this.facebookTrackOrderPaid(
        document
          .querySelector(".page-thank-you")
          .getAttribute("data-order-value")
      );
      this.gazetaPixelTrackPurchaseFinishedEvent();
    }

    // Add event listeners only on product page
    if (document.querySelectorAll(".single-product").length > 0) {
      this.singleProductWatchScroll();
      this.singleProductShowProductInterestsRate();

      this.gazetaPixelTrackViewProductEvent({
        id: this.singleProduct.id,
        name: this.singleProduct.name,
        price: parseFloat(this.singleProduct.price.split("<span>")[0]).toFixed(
          2
        ),
        category: "",
        quantity: parseInt(this.singleProduct.quantity),
      });
    }

    this.registerGlobalEvents();
    this.mountCarousels();
    this.singleProductMountCarouselOnMobile();
    // this.cartValidatePostCode("[name=billing_postcode]");
    this.getFavouriteProductsList();
  },
  methods: {
    actWhenElementIsInViewport(selector, action, onHidden = null) {
      const elementToTriggerAction = document.querySelector(selector);

      if (elementToTriggerAction) {
        let observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting === true) action();

            if (typeof onHidden === "function" && !entries[0].isIntersecting) {
              onHidden();
            }
          },
          {
            root: null,
            rootMargin: "0px",
            threshold: 0,
          }
        );

        observer.observe(elementToTriggerAction);
      }
    },

    carouselSingleProduct() {
      return this.carousels.find(
        (item) => item.details.element === CAROUSEL_PRODUCT_MAIN.element
      );
    },

    carouselFeaturedProduct() {
      const visibleFeaturedCarousel =
        CAROUSELS_FEATURED_PRODUCTS[this.home.featuredGroupIndex];

      const carousel = this.carousels.filter(
        (item) => item.details.element === visibleFeaturedCarousel.element
      );

      if (carousel) return carousel[0];

      throw new Error("Carousel not found...");
    },

    carouselFeaturedCategories() {
      return this.carousels.find(
        (item) => item.details.element === CAROUSEL_FEATURED_CATEGORIES.element
      );
    },

    carouselFeaturedProductsMoveToNextSlide() {
      const carousel = this.carouselFeaturedProduct();

      carousel.glide.go(">");
    },

    carouselFeaturedProductsMoveToPreviousSlide() {
      const carousel = this.carouselFeaturedProduct();

      carousel.glide.go("<");
    },

    carouselFeaturedCategoriesMoveToNextSlide() {
      const carousel = this.carouselFeaturedCategories();

      carousel.glide.go(">");
    },

    carouselFeaturedCategoriesMoveToPreviousSlide() {
      const carousel = this.carouselFeaturedCategories();

      carousel.glide.go("<");
    },

    unmountCarousel(carousel) {
      console.log("unmountCarousel", carousel);

      const index = this.carousels.findIndex(
        (item) => item.details.element === carousel.element
      );

      if (index === -1) return false;

      // unmount carousel

      this.carousels[index].glide.destroy();

      // remove carousel from array

      this.carousels.splice(index, 1);

      document.querySelectorAll(carousel.element).forEach((element) => {
        element.classList.remove("visible");
      });
    },

    mountCarousel(carousel) {
      if (document.querySelectorAll(carousel.element).length < 1) return;

      const findElement = (item) => item.glide.selector === carousel.element;

      let carouselsFound = this.carousels.filter(findElement);

      if (carouselsFound.length > 0) return false;

      setTimeout(() => {
        // Iterate over every element with `carousel.element` selector
        document
          .querySelectorAll(carousel.element)
          .forEach((singleCarousel) => {
            this.carousels.push({
              glide: new Glide(singleCarousel, carousel.options).mount(),
              details: carousel,
            });
          });
      }, 0);

      setTimeout(() => {
        document.querySelectorAll(carousel.element).forEach((element) => {
          element.classList.add("visible");
        });
      }, 100);
    },

    mountCarousels() {
      [
        CAROUSEL_FEATURED_CATEGORIES,
        CAROUSEL_CUSTOMERS_ALSO_BOUGHT,
        CAROUSELS_FEATURED_PRODUCTS[0],
        CAROUSEL_BLOG_FEATURED_PRODUCTS,
      ].forEach((carousel) => {
        this.mountCarousel(carousel);
      });
    },

    registerGlobalEvents() {
      const mobileMenu = document.querySelector("main");

      mobileMenu.addEventListener(
        "transitionend",
        () => mobileMenu.classList.remove(ITEM_ANIMATABLE_CLASS),
        false
      );
    },

    async initializeState() {
      if (window.State.singleProduct) {
        this.singleProduct = window.State.singleProduct;

        this.singleProductGetVariant();
      }

      if (window.State.checkout) this.checkout = window.State.checkout;
      if (window.State.order) this.order = window.State.order;

      if (window.State.productArchive) {
        this.productArchive = window.State.productArchive;

        this.productArchive.filters = this.productArchiveMapFilters();
        this.productArchive.page = this.productArchive.page
          ? this.productArchive.page
          : 0;
      }

      this.getCartContentsFromServer();

      this.favouriteProducts = this.getFavouriteProductIds();
    },

    async getCartContentsFromServer() {
      this.isAsyncRequestInProgress = true;

      this.cart = await runServerAction(ACTIONS.getCartContents);

      this.isAsyncRequestInProgress = false;
    },

    async getFavouriteProductsList() {
      if (!document.getElementById("favourites")) return false;

      const ids = this.getFavouriteProductIds();

      if (ids.length < 1) return false;

      this.productArchive.isLoading = true;

      const products = await runServerAction(ACTIONS.getProductsByIds, {
        ids: JSON.stringify(ids),
      });

      products.forEach((product) =>
        this.productArchive.loadedProducts.push(product)
      );

      this.productArchive.isLoading = false;
    },

    addProductToFavourites(productId) {
      productId = parseInt(productId);

      let savedValue = localStorage.getItem(LOCAL_STORAGE.favouriteProducts);

      if (!savedValue) savedValue = "";

      const favouriteProducts = this.getFavouriteProductIds();

      if (!favouriteProducts.includes(productId)) {
        favouriteProducts.push(productId);

        this.showNotification("Produkt został dodany do ulubionych!");
      } else {
        favouriteProducts.splice(favouriteProducts.indexOf(productId), 1);

        this.showNotification("Produkt został usunięty z ulubionych!");
      }

      this.favouriteProducts = favouriteProducts;

      localStorage.setItem(LOCAL_STORAGE.favouriteProducts, favouriteProducts);
    },

    getFavouriteProductIds() {
      let savedValue = localStorage.getItem(LOCAL_STORAGE.favouriteProducts);

      if (!savedValue) savedValue = "";

      return savedValue
        .split(",")
        .map((id) => parseInt(id))
        .filter(function(value) {
          return !Number.isNaN(value);
        });
    },

    removeProductFromCart() {
      runServerAction(ACTIONS.removeFromCart);
    },

    toggleTab(event, mainTabElementSelector = ".tabs__item") {
      const tab = event.target.closest(mainTabElementSelector);

      if (tab) tab.classList.toggle("visible");

      const iconsInTab = tab.querySelectorAll(".icon--plus, .icon--minus");

      if (iconsInTab[0]) {
        iconsInTab[0].classList.toggle("icon--plus");
        iconsInTab[0].classList.toggle("icon--minus");
      }
    },

    markAttributeAsActive() {},

    singleProductShowStickyBar() {
      const addToCartButton = document.querySelector(
        ".single-product__add-to-basket"
      );

      if (addToCartButton !== null) {
        const position = addToCartButton.getBoundingClientRect();

        if (position.top < 0) {
          this.singleProductStickyBarVisible = true;
        }
      }
    },

    singleProductHideStickyBar() {
      this.singleProductStickyBarVisible = false;
    },

    singleProductShowPhoto(photoUrl) {
      this.singleProduct.photo = photoUrl;
    },

    singleProductShowSizeChart() {
      this.showAside(ASIDES.sizeChart);
    },

    singleProductShowReviewAside() {
      this.showAside(ASIDES.review);
    },

    async singleProductShowCart() {
      this.cart = await runServerAction(ACTIONS.getCartContents);

      this.showAside(ASIDES.cart);
    },

    singleProductMountCarouselOnMobile() {
      if (this.isMobileScreen) {
        this.mountCarousel(CAROUSEL_PRODUCT_MAIN);
      }
    },

    showAside(aside) {
      this.hideAside();

      makeBodyScrollable(aside);

      this.asideOpened = aside;

      const wrapper = document.querySelector("main");

      wrapper.classList.add(aside);
      wrapper.classList.add(ITEM_VISIBLE_CLASS);
      wrapper.classList.add(ITEM_ANIMATABLE_CLASS);
    },

    hideAside() {
      const wrapper = document.querySelector("main");

      wrapper.classList = "";
      wrapper.classList.add(ITEM_ANIMATABLE_CLASS);

      makeBodyScrollable(false);
      this.asideOpened = false;
    },

    async singleProductCountAdd(productId) {
      this.status.isDuringProductCountIncrease = productId;

      this.singleProduct.quantity++;

      await this.singleProductGetVariant();

      this.status.isDuringProductCountIncrease = false;
    },

    async singleProductCountSubtract(productId) {
      this.status.isDuringProductCountDecrease = productId;

      if (this.singleProduct.quantity > 1) this.singleProduct.quantity--;

      await this.singleProductGetVariant();

      this.status.isDuringProductCountDecrease = false;
    },

    singleProductSetSize(size) {
      this.singleProduct.size = size;

      this.singleProductGetVariant();
    },

    singleProductSetColor(color) {
      this.singleProduct.color = color;

      const refreshCarousel = true;

      this.singleProductGetVariant(refreshCarousel);
    },

    async singleProductAddProductToCart() {
      this.status.isAddToCartButtonBlockedBy = this.singleProduct.id;
      this.cart = await runServerAction(ACTIONS.addToCart, {
        quantity: this.singleProduct.quantity,
        id: this.singleProduct.id,
      });

      this.showAside(ASIDES.cart);
      this.status.isAddToCartButtonBlockedBy = false;

      this.facebookTrackAddToCartEvent();

      this.gazetaPixelTrackAddToCartEvent({
        id: this.singleProduct.id,
        name: this.singleProduct.name,
        price: parseFloat(this.singleProduct.price.split("<span>")[0]).toFixed(
          2
        ),
        category: "",
        quantity: parseInt(this.singleProduct.quantity),
      });
    },

    async singleProductRemoveProductFromCart() {
      this.cart = await runServerAction(ACTIONS.removeFromCart, {
        quantity: this.singleProduct.quantity,
        id: this.singleProduct.id,
      });

      this.showAside(ASIDES.cart);
    },

    async singleProductGetVariant(refreshCarousel = false) {
      this.status.isAddToCartButtonBlockedBy = this.singleProduct.id;

      const result = await runServerAction(ACTIONS.getVariant, {
        quantity: this.singleProduct.quantity,
        size: this.singleProduct.size,
        color: this.singleProduct.color,
        id: this.singleProduct.parentId,
      });

      this.singleProduct.available = result.available;

      if (result.available) {
        this.singleProduct.id = result.id;
        this.singleProduct.parentId = result.parentId;
        this.singleProduct.price = result.price;
        this.singleProduct.inStock = result.inStock;
        this.singleProduct.onSale = result.onSale;
        this.singleProduct.photo = result.photo;

        if (refreshCarousel) {
          this.unmountCarousel(CAROUSEL_PRODUCT_MAIN);
          // Check if  clicked variant photo is already in photos array
          // If yes, move it to the first position of photos array
          // If not, add it to the first position of photos array
          const photoOfClickedVariant = this.singleProduct.photos.find(
            (photo) => {
              return (
                this.normalizeImageUrl(photo.xl) ===
                this.normalizeImageUrl(result.photo)
              );
            }
          );
          if (photoOfClickedVariant) {
            this.singleProduct.photos = [
              photoOfClickedVariant,
              ...this.singleProduct.photos.filter(
                (photo) => photo.xl !== photoOfClickedVariant.xl
              ),
            ];
          } else {
            this.singleProduct.photos = [
              {
                xl: result.photo,
                xs: result.photo,
              },
              ...this.singleProduct.photos,
            ];
          }

          this.mountCarousel(CAROUSEL_PRODUCT_MAIN);
        }
      }

      this.singleProduct.availabilityChecked = true;
      this.status.isAddToCartButtonBlockedBy = false;
    },

    normalizeImageUrl(url) {
      const urlObject = new URL(url);

      return urlObject.pathname;
    },

    isProductAddedToFavourites(id) {
      id = parseInt(id);

      if (!id) return false;

      return this.favouriteProducts.includes(parseInt(id));
    },

    async cartSetQuantity(productKey, quantity) {
      this.cart = await runServerAction(ACTIONS.setQuantity, {
        quantity: quantity,
        key: productKey,
      });
    },

    async cartDecreaseQuantity(product) {
      this.status.isDuringProductCountDecrease = product.id;

      await this.cartSetQuantity(product.key, parseInt(product.quantity) - 1);

      this.status.isDuringProductCountDecrease = false;
    },

    async cartIncreaseQuantity(product) {
      this.status.isDuringProductCountIncrease = product.id;

      await this.cartSetQuantity(product.key, parseInt(product.quantity) + 1);

      this.status.isDuringProductCountIncrease = false;
    },

    async cartRemoveProduct(productKey) {
      this.status.isDuringRemoveFromCart = productKey;

      this.cart = await runServerAction(ACTIONS.removeFromCart, {
        key: productKey,
      });

      this.status.isDuringRemoveFromCart = false;
    },

    async cartApplyCoupon(coupon) {
      const result = await runServerAction(ACTIONS.applyCoupon, {
        coupon: coupon,
      });

      if (result.summary.coupons.length < 1)
        this.showNotification(`Kupon "${coupon}" nie istnieje!`);

      this.cart = result;
    },

    async cartRemoveCoupon(coupon) {
      const result = await runServerAction(ACTIONS.removeCoupon, {
        coupon: coupon,
      });

      if (result.summary.coupons.length < 1)
        this.showNotification(`Kupon "${coupon}" został usunięty!`);

      this.cart = result;
    },

    cartValidatePostCode(fieldSelector) {
      setTimeout(() => {
        const fields = document.querySelectorAll(fieldSelector);

        if (fields.length < 1) return;

        fields.forEach((field) => {
          IMask(field, {
            mask: "00-000",
          });
        });
      }, 0);
    },

    checkoutUpdateWooCommerce() {
      document.body.dispatchEvent(new Event("update_checkout"));
    },

    checkoutRegisterEvents() {
      document
        .querySelectorAll(".update_totals_on_change input")
        .forEach((field) => {
          field.addEventListener("change", (event) => {
            this.checkoutUpdateWooCommerce();

            if (this.isInPost) this.checkoutGetInPostPoints(true);
          });
        });

      // one and only use of jQuery due to not have separate event on shipping method change
      if (window.jQuery) {
        const radioButton = "input[name='shipping_method']";

        jQuery(document).on("change", radioButton, () => {
          const currentShippingMethod = document.querySelector(
            `${radioButton}:checked`
          ).value;

          this.checkoutUpdateShippingMethod(currentShippingMethod);
        });

        jQuery(document).on(
          "click",
          ".cart--page__review-order__item--coupon i",
          (event) => {
            const coupon = event.currentTarget.getAttribute("data-coupon");

            this.cartRemoveCoupon(coupon);

            setTimeout(() => this.checkoutUpdateWooCommerce(), 1000);
          }
        );
      }
    },

    async checkoutGetInPostPoints(forceUpdate = false) {
      let searchParams = {};

      const fieldPrefix = this.checkoutGetFieldPrefix();

      let city = document.querySelector(`[name="${fieldPrefix}_city"]`).value;
      let postCode = document.querySelector(`[name="${fieldPrefix}_postcode"]`)
        .value;
      let address = document.querySelector(`[name="${fieldPrefix}_address_1"]`)
        .value;

      if (city !== "") {
        searchParams["city"] = city;
      }

      if (address !== "") {
        searchParams["address"] = address;
      }

      const result = await runServerAction(
        ACTIONS.checkoutFindInpostPoints,
        searchParams
      );

      if (!this.checkout.inPostChosenPoint || forceUpdate)
        this.checkout.inPostChosenPoint = result[0];

      this.checkout.closestPoints = result;
    },

    checkoutShowInPostModal() {
      this.showAside(ASIDES.inpost);

      if (!window.InPostMap) {
        // load widget
        easyPack.init({
          showTypesFilters: false,
          showSearchBar: false,
          points: {
            types: ["parcel_locker_only"],
          },
          map: {
            useGeolocation: false,
            initialTypes: ["parcel_locker_only"],
            initialZoom: 10,
            visiblePointsMinZoom: 10,
          },
        });

        window.InPostMap = easyPack.mapWidget("inpost-map", (point) => {
          this.checkout.inPostChosenPoint = {
            id: point.name,
            address: `${point.address.line1} ${point.address.line2}`,
            description: "-",
          };

          this.hideAside();
        });
      }

      const fieldPrefix = this.checkoutGetFieldPrefix();

      let city = document.querySelector(`[name="${fieldPrefix}_city"]`).value;

      if (city) {
        window.InPostMap.searchPlace(city);
      }
    },

    async checkoutUpdateShippingMethod(method) {
      this.checkout.shippingMethod = method;
    },

    checkoutGetFieldPrefix() {
      return this.checkout.wantDifferentShippingAddress == true
        ? "shipping"
        : "billing";
    },

    headerShowSubmenu(submenu) {
      if (this.isMobileScreen) return false;

      this.submenuOpened = submenu;
    },

    headerGetSubmenuElement(submenu) {
      return document.querySelector(`#submenu-of-${submenu}`);
    },

    headerShowMobileSubmenu(submenu, event) {
      if (!this.isMobileScreen) return false;

      const submenuElement = this.headerGetSubmenuElement(submenu);

      if (!submenuElement) return false;

      this.submenuOpened = submenu;

      event.preventDefault();

      submenuElement.classList.add(ITEM_VISIBLE_CLASS);
      submenuElement.classList.add(ITEM_ANIMATABLE_CLASS);
    },

    headerHideSubmenu() {
      this.headerShowSubmenu(false);
    },

    headerHideMobileMenu() {
      if (!this.submenuOpened) {
        this.hideAside();
      } else {
        const submenuElement = this.headerGetSubmenuElement(this.submenuOpened);

        submenuElement.classList.remove(ITEM_VISIBLE_CLASS);
        submenuElement.classList.remove(ITEM_ANIMATABLE_CLASS);

        this.submenuOpened = false;
      }
    },

    headerToggleMobileMenu() {
      this.showAside(ASIDES.menu);
    },

    toggleSearch() {
      this.showAside(ASIDES.search);

      document.querySelector("[name='s']").focus();
      document.querySelector("[name='s']").select();

      this.facebookTrackSearch();
    },

    gazetaPixelTrackPurchaseStartEvent() {
      if (!this.gazetaPixelIsInitialized()) {
        return false;
      }

      try {
        c2cPixel("event", "PurchaseStart", {
          revenue: this.order.revenue,
          productsArray: this.order.products,
        });
      } catch (error) {
        console.log("PurchaseStart error", error);
      }
    },

    gazetaPixelTrackPurchaseFinishedEvent() {
      if (!this.gazetaPixelIsInitialized()) {
        return false;
      }

      try {
        console.log("event", "PurchaseFinished", {
          transactionId: this.order.transactionId,
          revenue: this.order.revenue,
          productsArray: this.order.products,
        });

        c2cPixel("event", "PurchaseFinished", {
          transactionId: this.order.transactionId,
          revenue: this.order.revenue,
          productsArray: this.order.products,
        });
      } catch (error) {
        console.log("PurchaseFinished error", error);
      }
    },

    gazetaPixelTrackViewProductEvent({ id, name, price, category }) {
      if (!this.gazetaPixelIsInitialized()) {
        return false;
      }
      try {
        c2cPixel("event", "ViewProduct", {
          id: id,
          name: name,
          price: price,
          brand: "Lores",
          category: category,
        });
      } catch (error) {
        console.log("ViewProduct error", error);
      }
    },

    gazetaPixelTrackAddToCartEvent({ id, name, price, category, quantity }) {
      if (!this.gazetaPixelIsInitialized()) {
        return false;
      }
      try {
        c2cPixel("event", "AddToCart", {
          id: id,
          name: name,
          price: price,
          brand: "Lores",
          category: category,
          quantity: quantity,
        });
      } catch (error) {
        console.log("AddToCart error", error);
      }
    },

    facebookIsInitialized() {
      return typeof fbq !== "undefined";
    },

    googleAnalyticsIsInitialized() {
      return typeof gtag !== "undefined";
    },

    gazetaPixelIsInitialized() {
      return typeof c2cPixel !== "undefined";
    },

    facebookTrackAddToCartEvent() {
      if (this.facebookIsInitialized()) {
        fbq("track", "AddToCart");
      }
    },

    facebookTrackSearch() {
      if (this.facebookIsInitialized()) {
        fbq("track", "Search");
      }
    },

    /**
     * Run fbq("track", "Purchase", ...) when fbq function is ready
     *
     * check fbq on every second, then stop the interval when it was
     * triggered more than 5 times
     *
     * @param {number} value
     */
    facebookTrackOrderPaid(value) {
      if (!!value) {
        let timesIntervalTriggered = 0;

        const interval = setInterval(() => {
          if (this.facebookIsInitialized() || timesIntervalTriggered >= 5) {
            clearInterval(interval);

            fbq("track", "Purchase", {
              value: value,
              currency: "PLN",
            });

            console.log("Purchase tracked!");
          }

          timesIntervalTriggered++;
        }, 1000);
      }
    },

    /**
     * Send "InitiateCheckout" event to FB
     */
    facebookTrackCheckout() {
      if (this.facebookIsInitialized()) {
        fbq("track", "InitiateCheckout");
      }
    },

    /**
     * Send "checkout" event to Google:
     *
     * https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
     */
    googleAnalyticsTrackCheckout() {
      if (this.googleAnalyticsIsInitialized()) {
        gtag("event", "begin_checkout", {
          items: window.State.cart.products.map((product) => {
            return {
              id: product.id,
              name: product.name,
              price: product.price,
              brand: "Lores",
              variant: product.size + " " + product.color,
              quantity: product.quantity,
            };
          }),
          coupon: window.State.checkout.couponCodes.join(", "),
        });
      }
    },

    newsletterShowContent(event) {
      const section = document.querySelector(".newsletter");

      section.classList.add(ITEM_ANIMATABLE_CLASS);

      setTimeout(() => {
        section.classList.add(ITEM_VISIBLE_CLASS);
      }, 200);
    },

    newsletterSubscribe(event) {
      event.preventDefault();

      // Subscribe to edrone
      const emailField = event.currentTarget.querySelector(
        'input[type="email"]'
      );

      const _edrone = window._edrone || {};

      _edrone.customer_tags = "FROM_POPUP";
      _edrone.email = emailField.value ? emailField.value : _edrone.email;
      _edrone.first_name = _edrone.first_name ? _edrone.first_name : "";
      _edrone.action_type = "subscribe";

      _edrone.init();

      this.showNotification(
        "Dziękujemy za zapis do newslettera! Sprawdź teraz swoją skrzynkę pocztową, aby dokończyć zapis."
      );

      const newsletter = document.querySelector(".newsletter");

      // Hide checkboxes
      newsletter.classList.remove(ITEM_VISIBLE_CLASS);

      setTimeout(() => {
        newsletter.classList.remove(ITEM_ANIMATABLE_CLASS);

        // Reset the form
        newsletter.querySelector("form").reset();
      }, 200);
    },

    homeSetFeaturedGroup(groupIndex) {
      this.home.featuredGroupIndex = groupIndex;
    },

    homeRefreshFeaturedProductsCarousel(index) {
      if (!CAROUSELS_FEATURED_PRODUCTS[index]) return;

      const findElement = (item) =>
        item.details.element === CAROUSELS_FEATURED_PRODUCTS[index].element;

      let carouselAlreadyMounted = this.carousels.filter(findElement);

      // Unmount the Glide carousel from the element first
      if (carouselAlreadyMounted.length > 0) {
        carouselAlreadyMounted[0].glide.destroy();

        this.carousels.splice(this.carousels.findIndex(findElement), 1);
      }

      // Recreate Glide carousel
      if (CAROUSELS_FEATURED_PRODUCTS[index]) {
        this.mountCarousel(CAROUSELS_FEATURED_PRODUCTS[index]);
      }
    },

    homeNextFeaturedGroup() {
      this.home.featuredGroupIndex++;

      const maxIndex = document.querySelectorAll(
        ".featured-products__tabs > li"
      ).length;

      if (this.home.featuredGroupIndex > maxIndex - 1)
        this.homeSetFeaturedGroup(0);
    },

    homePreviousFeaturedGroup() {
      this.home.featuredGroupIndex--;

      const maxIndex = document.querySelectorAll(
        ".featured-products__tabs > li"
      ).length;

      if (this.home.featuredGroupIndex < 0)
        this.homeSetFeaturedGroup(maxIndex - 1);
    },

    async homeGetInstagramPhotos() {
      if (this.home.instagramPhotos.length > 0) return;

      this.home.instagramPhotos = await runServerAction(
        ACTIONS.homeGetInstagramPhotos
      );

      this.mountCarousel(CAROUSEL_INSTAGRAM_FEED);
    },

    homeWatchInstagramSectionVisible() {
      this.actWhenElementIsInViewport(".home-instagram", () =>
        this.homeGetInstagramPhotos()
      );
    },

    singleProductWatchScroll() {
      this.actWhenElementIsInViewport(
        ".single-product__add-to-basket",
        () => this.singleProductHideStickyBar(),
        () => this.singleProductShowStickyBar()
      );

      this.actWhenElementIsInViewport(
        ".footer",
        () => this.singleProductHideStickyBar(),
        () => this.singleProductShowStickyBar()
      );
    },

    singleProductShowProductInterestsRate() {
      setTimeout(() => {
        const interestsRate = document.querySelector("#product-interests-rate");

        if (interestsRate) {
          interestsRate.classList.add(ITEM_VISIBLE_CLASS);
        }
      }, 3000);
    },

    singleProductHideProductInterestsRate() {
      const interestsRate = document.querySelector("#product-interests-rate");

      if (interestsRate) {
        interestsRate.classList.remove(ITEM_VISIBLE_CLASS);
      }
    },

    productArchiveToggleSelect(filterName) {
      if (this.filterOpened !== filterName && this.filterOpened !== false) {
        this.filterOpened = filterName;
      } else {
        this.filterOpened = this.filterOpened !== false ? false : filterName;
      }
    },

    productArchiveRemoveFilter(filterId) {
      const index = this.productArchive.filters.findIndex(
        (item) => item.id === filterId
      );

      if (index >= 0) {
        // Remove from filters array
        this.productArchive.filters.splice(index, 1);

        // Uncheck checkbox
        document.querySelector(`[data-id="${filterId}"]`).checked = false;
      } else {
        this.productArchive.filters = [];
      }

      this.productArchiveFilterBy();
    },

    async productArchiveFilterBy() {
      this.productArchiveToggleSelect(false);

      // Save filters
      const filters = this.productArchiveMapFilters();

      this.productArchiveAddFiltersToURL(filters);

      location.reload();
    },

    productArchiveAddFiltersToURL(filters) {
      /*
        group by filter types, example output:

        [
          'kolor': ['czarny', 'bialy']
        ]
      */
      const filterTypes = [];

      filters.forEach((filter) => {
        if (!filterTypes.hasOwnProperty(filter.friendlyTaxonomyName)) {
          filterTypes[filter.friendlyTaxonomyName] = [];
        }

        filterTypes[filter.friendlyTaxonomyName].push(filter.slug);
      });

      /*
        create URL search params object, example output:

        ?kolor=czarny,bialy&grubosc=20-den
      */
      const urlSearchParams = new URLSearchParams();

      Object.keys(filterTypes).forEach((filterName) => {
        urlSearchParams.append(filterName, filterTypes[filterName].join());
      });

      urlSearchParams.sort();

      /*
        modify the url with query object created above
      */
      let urlWithQueryParams =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        urlSearchParams.toString();

      window.history.pushState(
        { path: urlWithQueryParams },
        "",
        urlWithQueryParams
      );
    },

    productArchiveMapFilters() {
      const filters = [];

      [...document.querySelectorAll("[data-taxonomy]:checked")].forEach(
        (checkbox) => {
          filters.push({
            taxonomy: checkbox.getAttribute("data-taxonomy"),
            id: checkbox.getAttribute("data-id"),
            slug: checkbox.getAttribute("data-slug"),
            name: checkbox.getAttribute("data-name"),
            friendlyTaxonomyName: checkbox.getAttribute(
              "data-friendly-taxonomy-name"
            ),
          });
        }
      );

      return filters;
    },

    showNotification(text = "") {
      if (text === "") return;

      this.notification = {
        content: text,
        type: "info",
      };

      setTimeout(() => {
        this.notification.content = "";
      }, 5000);
    },

    trackMainSliderClick(bannerPosition) {
      this.sendGoogleAnalyticsClickEvent({
        name: "Banner główny na stronie głównej",
        creative: "Banner główny na stronie głównej",
        position: bannerPosition,
      });
    },

    trackMenuBannerClick(bannerPosition) {
      this.sendGoogleAnalyticsClickEvent({
        name: "Banner kategorii promowanej w menu rozwijanym",
        creative: "Promowana kategoria w menu rozwijanym",
        position: bannerPosition,
      });
    },

    trackProductCategoryBannerClick(bannerPosition) {
      this.sendGoogleAnalyticsClickEvent({
        name: "Banner kategorii promowanej na stronie głównej",
        creative: "Promowana kategoria",
        position: bannerPosition,
      });
    },

    sendGoogleAnalyticsClickEvent(args) {
      if (typeof ga === "function") {
        ga("require", "ec");
        ga("ec:addPromo", {
          name: args.name,
          creative: args.creative,
          position: args.position,
        });
        ga("ec:setAction", "promo_click");
        ga("send", "event", "Promocja wewnętrzna", "click", args.name);
      }
    },

    getRandomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
});
