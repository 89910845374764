<template>
  <i
    :class="[
      'icon',
      'icon--' + name,
      'icon--' + color,
      'icon--' + (isInline ? 'inline' : 'block'),
      extraClass
    ]"
  ></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    color: {
      type: String,
      default: "black",
    },
    isInline: {
      type: Boolean,
      default: true,
    },
    extraClass: {
      type: String,
      default: "",
    }
  },
};
</script>

<style></style>
