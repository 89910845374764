<template>
  <span v-if="counter">{{ prependText }} {{ counter }} </span>
</template>

<script>
// Usage: <countdown to="26 April 2021 00:00:00" prepend-text="Jeszcze tylko"/>

export default {
  props: {
    to: {
      type: String
    },
    prependText: {
      type: String
    }
  },
  data: function() {
    return {
      counter: ""
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const endDate = Date.parse(this.to);
      const now = Date.now();

      if (now > endDate) {
        this.counter = "00h 00m 00s";
      }

      setInterval(() => {
        console.log(this.getCountdown(endDate));
        this.counter = this.getCountdown(endDate);
      }, 1000);
    },
    getCountdown(endDate) {
      const now = Date.now();

      // get total seconds between the times
      let delta = Math.abs(endDate - now) / 1000;

      // calculate (and subtract) whole hours
      const hours = Math.floor(delta / 3600);
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      const seconds = parseInt(delta % 60);

      return `${hours}h ${minutes}m ${seconds}s`;
    }
  }
};
</script>

<style></style>
