<template>
  <div
    :class="[
      'loading-spinner',
      'loading-spinner--' + color,
      'loading-spinner--' + size,
      extraClass
    ]"
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "orange",
    },
    size: {
      type: String,
      default: "md",
    },  
    marginTop: {
      type: String,
      default: "md",
    },
    extraClass: {
      type: String,
      default: "",
    }
  },
};
</script>

<style></style>
