export const ACTIONS = {
  addToCart: "product_add_to_cart",
  removeFromCart: "cart_remove_product",
  getVariant: "product_get_variant",
  getCartContents: "cart_get_contents",
  setQuantity: "cart_set_quantity",
  applyCoupon: "cart_apply_coupon",
  removeCoupon: "cart_remove_coupon",
  archiveLoadMore: "product_archive_load_more",
  archiveFilterBy: "product_archive_load_more",
  checkoutFindInpostPoints: "checkout_find_inpost_points",
  homeGetInstagramPhotos: "home_get_instagram_photos",
  getProductsByIds: "products_get_by_ids"
};

export const ASIDES = {
  cart: "opened--cart",
  sizeChart: "opened--size-chart",
  review: "opened--review",
  inpost: "opened--inpost",
  search: "opened--search",
  menu: "opened--menu",
};

export const LOCAL_STORAGE = {
  favouriteProducts: "lores_favourite_products",
  privacyPolicy: "lores_privacy_policy",
};
